import once from 'lodash.once';

export default function (elements, callback) {
  if (!elements.length) return;

  const initialise = once(callback);

  const observer = new IntersectionObserver((entries, observer) => {
    entries
      .filter(entry => entry.isIntersecting)
      .forEach(entry => {
        initialise();

        observer.unobserve(entry.target);
      });
  });

  elements.forEach(element => {
    observer.observe(element);
  });
}
